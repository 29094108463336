<template>
  <GMapMap
    ref="myMapRef"
    :center="mapCenter"
    :zoom="zoom"
    style="width: 100%; height: 100%"
    class="h-full"
    :options="{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: true,
    }"
    @bounds_changed="handleBoundsChanged"
  >
    <GMapMarker
      v-for="(property, index) in properties"
      :key="index"
      :icon="
        !defaultMarker
          ? {
              url: '/maps-marker.svg',
              size: { width: 14, height: 14 },
              anchor: { x: 7, y: 7 },
            }
          : ''
      "
      :position="{ lat: Number(property.latitude), lng: Number(property.longitude) }"
      @mouseover="showInfoWindow(property, $event)"
      @mouseout="hideInfoWindow"
    />
  </GMapMap>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { DICTIONARIES, PROPERTIES_SALE_STATUSES } from '~/constants'
import type { LibraryItem, PropertyTypes } from '~/types'

const emits = defineEmits(['filter-status', 'updated:position'])

type Props = {
  properties: Partial<PropertyTypes.Property>[]
  filters?: PropertyTypes.Filters
  zoom?: number
  centerByMarker?: boolean
  showStatuses?: boolean
  defaultMarker?: boolean
  showControls?: boolean
  showPropertyPopover?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  filters: undefined,
  zoom: 12,
  showControls: true,
  showPropertyPopover: false,
})

const handleBoundsChanged = (bounds: any) => {
  emits('updated:position', {
    topLeftLat: bounds.getNorthEast().lat(),
    topLeftLng: bounds.getSouthWest().lng(),
    bottomRightLat: bounds.getSouthWest().lat(),
    bottomRightLng: bounds.getNorthEast().lng(),
  })
}

const calculateCenter = (filters: {
  top_left_lat: number
  top_left_lon: number
  bottom_right_lat: number
  bottom_right_lon: number
}) => {
  if (!filters) return { lat: 25.2048, lng: 55.2708 }

  const avgLat = (filters.top_left_lat + filters.bottom_right_lat) / 2
  const avgLng = (filters.top_left_lon + filters.bottom_right_lon) / 2

  return { lat: avgLat, lng: avgLng }
}

const mapCenter = ref(
  props.filters?.top_left_lat
    ? calculateCenter({
        top_left_lat: Number(props.filters.top_left_lat),
        top_left_lon: Number(props.filters.top_left_lon),
        bottom_right_lat: Number(props.filters.bottom_right_lat),
        bottom_right_lon: Number(props.filters.bottom_right_lon),
      })
    : props.centerByMarker
    ? { lat: props.properties[0].latitude, lng: props.properties[0].longitude }
    : { lat: 25.2048, lng: 55.2708 }
)

const myMapRef = ref(null)
const infoWindow = ref<google.maps.InfoWindow | null>(null)
const statuses = ref<LibraryItem[]>([])

onMounted(() => {
  addControls()
  initializeMap()
})

const initializeMap = () => {
  if (!myMapRef.value) return
  myMapRef.value.$mapPromise?.then((map: any) => {
    // Verify and adjust the bounds
    const topLeftLat = Number(props.filters?.top_left_lat)
    const topLeftLon = Number(props.filters?.top_left_lon)
    const bottomRightLat = Number(props.filters?.bottom_right_lat)
    const bottomRightLon = Number(props.filters?.bottom_right_lon)

    if (isNaN(topLeftLat) || isNaN(topLeftLon) || isNaN(bottomRightLat) || isNaN(bottomRightLon)) {
      return
    }

    const bounds = new google.maps.LatLngBounds(
      { lat: bottomRightLat, lng: topLeftLon }, // Adjusted southwest corner
      { lat: topLeftLat, lng: bottomRightLon } // Adjusted northeast corner
    )

    // For some reason, the map is zoomed in too much when setting the bounds
    map.fitBounds(bounds)
    // 0.7 is the best value i found for zooming out a bit to almost match the previous coordinates
    map.setZoom(map.getZoom() + 0.7)

    // Set a minimum zoom level
    google.maps.event.addListener(map, 'zoom_changed', function () {
      if (map.getZoom() < 10) map.setZoom(10)
    })
  })
}

const addControls = () => {
  if (!myMapRef.value) return
  myMapRef.value.$mapPromise?.then((map: any) => {
    if (!props.showControls) return
    addControl(map, 'zoom', String(google.maps.ControlPosition.LEFT_TOP))
    addControl(map, 'center', String(google.maps.ControlPosition.LEFT_TOP))
    addControl(map, 'mapType', String(google.maps.ControlPosition.LEFT_TOP))

    if (props.showStatuses) {
      setTimeout(async () => {
        statuses.value = await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_SALE_STATUSES)
        addStatusesButtons(map)
      }, 200)
    }
    infoWindow.value = new google.maps.InfoWindow()
  })
}

const createButton = (
  html: string,
  classes: string,
  ariaLabel: string,
  onClick: () => void,
  isActive?: boolean,
  onMouseOver?: () => void,
  onMouseOut?: () => void
) => {
  const button = document.createElement('button')
  button.innerHTML = html
  button.className = classes + (isActive ? 'bg-primary-90 text-white' : 'bg-white text-black-90') // Add 'active' class if isActive is true
  button.setAttribute('aria-label', ariaLabel)
  button.addEventListener('click', onClick)
  button.addEventListener('mouseover', onMouseOver)
  button.addEventListener('mouseout', onMouseOut)
  return button
}

const statusesButtonsColorsMap = {
  [PROPERTIES_SALE_STATUSES.ANNOUNCEMENT]: 'bg-secondary-120',
  [PROPERTIES_SALE_STATUSES.PRE_SALE]: 'bg-success-100',
  [PROPERTIES_SALE_STATUSES.LAUNCH]: 'bg-alert-100',
  [PROPERTIES_SALE_STATUSES.ON_SALE]: 'bg-primary-100',
  [PROPERTIES_SALE_STATUSES.SOLD_OUT]: 'bg-error-100',
}

const addStatusesButtons = (map: any) => {
  const buttonDiv = document.createElement('div')
  buttonDiv.id = 'status-buttons'
  buttonDiv.classList.add('flex', 'flex-row', 'justify-start', 'rounded-xl', 'm-6', 'gap-2')

  statuses.value
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((status) =>
      [
        PROPERTIES_SALE_STATUSES.ANNOUNCEMENT,
        PROPERTIES_SALE_STATUSES.PRE_SALE,
        PROPERTIES_SALE_STATUSES.LAUNCH,
        PROPERTIES_SALE_STATUSES.ON_SALE,
      ].includes(status.code)
    )
    .forEach((status) => {
      const button = createButton(
        `<span class="size-3 rounded-full ${statusesButtonsColorsMap[status.code]}"></span>${status.name}`,
        'bg-white p-2 rounded-md flex justify-center shadow-lg text-subhead-4 flex-row gap-2 items-center ',
        status.name,
        () => {
          if (props.filters?.sale_status?.includes(status.id)) {
            button.classList.remove('bg-primary-90')
            button.classList.remove('text-white')

            button.classList.add('bg-white')
            button.classList.add('text-black-90')
          } else {
            button.classList.remove('bg-white')
            button.classList.remove('text-black-90')

            button.classList.add('bg-primary-90')
            button.classList.add('text-white')
          }
          emits('filter-status', status.id)
        },
        props.filters?.sale_status?.includes(status.id)
      )
      buttonDiv.appendChild(button)
    })

  map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(buttonDiv)
}

const controlsTypeMap = new Map([
  [
    'mapType',
    (map: google.maps.Map) => {
      const mapTypeControl = document.createElement('div')
      mapTypeControl.classList.add('flex', 'flex-row', 'mt-3', 'text-black-90')
      const mapButton = createButton(
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4232_82188)"><path d="M2 5.95715L12 9.5L22 5.95715L12 2.5L2 5.95715Z" stroke="currentColor" stroke-width="1.6" stroke-linejoin="round"/><path d="M2 10L12 13.5L22 10" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 14L12 17.5L22 14" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 18L12 21.5L22 18" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></g></svg>',
        'bg-white p-2 rounded-xl !text-2xl h-10 flex justify-center shadow-lg',
        'Change map type',
        () => {},
        false,
        () => {
          // show popover with map and satellite options
          mapButton.classList.add('text-primary-90')
          mapButton.classList.remove('text-black-90')
          popover.classList.remove('hidden')
        },
        () => {
          // hide popover with map and satellite options after 1 sec, if the cursor is not on the button or the popover
          setTimeout(() => {
            if (
              !(
                x.value > mapButton.getBoundingClientRect().left &&
                x.value < mapButton.getBoundingClientRect().right &&
                y.value > mapButton.getBoundingClientRect().top &&
                y.value < mapButton.getBoundingClientRect().bottom
              ) &&
              !(
                x.value > popover.getBoundingClientRect().left &&
                x.value < popover.getBoundingClientRect().right &&
                y.value > popover.getBoundingClientRect().top &&
                y.value < popover.getBoundingClientRect().bottom
              )
            ) {
              popover.classList.add('hidden')
              mapButton.classList.remove('text-primary-90')
              mapButton.classList.add('text-black-90')
            }
          }, 1000)
        }
      )

      // Create the popover element
      const popover = document.createElement('div')
      popover.classList.add(
        'absolute',
        'left-14',
        'top-4',
        'w-[222px]',
        'h-[112px]',
        'bg-white',
        'shadow-lg',
        'rounded-lg',
        'hidden',
        'z-10',
        'flex',
        'flex-row',
        'gap-2',
        'p-4'
      )
      popover.innerHTML = `
  <div class="cursor-pointer hover:bg-gray-100 w-1/2 text-center text-subhead-4 text-subhead-4" id="mapTypeRoadmap">
  <img id="mapTypeRoadmapImage" src="/roadmap.webp" class="w-full h-[56px] rounded-lg border border-solid border-black-100"/>
  Map
  </div>
  <div class="cursor-pointer hover:bg-gray-100 w-1/2 text-center text-body-2" id="mapTypeSatellite">
  <img id="mapTypeSatelliteImage" src="/satellite.webp" class="w-full h-[56px] rounded-lg border border-solid border-black-70"/>Satellite</div>
`

      // Add event listeners for the popover options
      const roadmapImage = popover.querySelector('#mapTypeRoadmapImage')
      const satelliteImage = popover.querySelector('#mapTypeSatelliteImage')
      const mapTypeRoadmap = popover.querySelector('#mapTypeRoadmap')
      const mapTypeSatellite = popover.querySelector('#mapTypeSatellite')
      if (!roadmapImage || !satelliteImage || !mapTypeRoadmap || !mapTypeSatellite) return
      mapTypeRoadmap.addEventListener('click', () => {
        map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
        mapTypeRoadmap.classList.add('text-subhead-4')
        mapTypeRoadmap.classList.add('text-black-100')
        roadmapImage.classList.add('border-[1.5px]')
        roadmapImage.classList.add('border-black-100')
        mapTypeRoadmap.classList.remove('text-body-2')
        mapTypeRoadmap.classList.remove('text-black-60')
        roadmapImage.classList.remove('border')
        roadmapImage.classList.remove('border-black-70')

        mapTypeSatellite.classList.remove('text-subhead-4')
        mapTypeSatellite.classList.remove('text-black-100')
        satelliteImage.classList.remove('border-[1.5px]')
        satelliteImage.classList.remove('border-black-100')
        mapTypeSatellite.classList.add('text-body-2')
        mapTypeSatellite.classList.add('text-black-60')
        satelliteImage.classList.add('border')
        satelliteImage.classList.add('border-black-70')
        popover.classList.add('hidden')
      })

      mapTypeSatellite.addEventListener('click', () => {
        map.setMapTypeId(google.maps.MapTypeId.SATELLITE)
        mapTypeSatellite.classList.add('text-subhead-4')
        mapTypeSatellite.classList.add('text-black-100')
        satelliteImage.classList.add('border-[1.5px]')
        satelliteImage.classList.add('border-black-100')
        mapTypeSatellite.classList.remove('text-body-2')
        mapTypeSatellite.classList.remove('text-black-60')
        satelliteImage.classList.remove('border')
        satelliteImage.classList.remove('border-black-70')

        mapTypeRoadmap.classList.remove('text-subhead-4')
        mapTypeRoadmap.classList.remove('text-black-100')
        roadmapImage.classList.remove('border-[1.5px]')
        roadmapImage.classList.remove('border-black-100')
        mapTypeRoadmap.classList.add('text-body-2')
        roadmapImage.classList.add('text-black-60')
        roadmapImage.classList.add('border')

        popover.classList.add('hidden')
      })

      mapTypeControl.appendChild(mapButton)
      mapTypeControl.appendChild(popover)
      return mapTypeControl
    },
  ],
  [
    'zoom',
    (map) => {
      const zoomControl = document.createElement('div')
      zoomControl.classList.add('flex', 'flex-col', 'rounded-xl', 'w-10')

      const zoomInButton = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5V11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11.25V19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19V12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H12.75V5Z" fill="currentColor"/></svg>',
        'bg-white p-2 rounded-t-md !text-2xl h-10 flex justify-center mt-3 shadow-lg',
        'Zoom in',
        () => map.setZoom(map.getZoom() + 1)
      )
      const zoomOutButton = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z" fill="currentColor"/></svg>',
        'bg-white p-2 rounded-b-md !text-2xl flex justify-center mb-3 shadow-lg',
        'Zoom out',
        () => map.setZoom(map.getZoom() - 1)
      )
      zoomControl.appendChild(zoomInButton)
      zoomControl.appendChild(zoomOutButton)
      return zoomControl
    },
  ],
  [
    'center',
    (map) => {
      const centerControl = document.createElement('div')
      centerControl.classList.add('flex', 'flex-col', 'rounded-xl', 'w-10')

      const button = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1812_17864)"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.63636C9.58909 7.63636 7.63636 9.58909 7.63636 12C7.63636 14.4109 9.58909 16.3636 12 16.3636C14.4109 16.3636 16.3636 14.4109 16.3636 12C16.3636 9.58909 14.4109 7.63636 12 7.63636ZM21.7527 10.9091C21.2509 6.36 17.64 2.74909 13.0909 2.24727V1.09091C13.0909 0.490909 12.6 0 12 0C11.4 0 10.9091 0.490909 10.9091 1.09091V2.24727C6.36 2.74909 2.74909 6.36 2.24727 10.9091H1.09091C0.490909 10.9091 0 11.4 0 12C0 12.6 0.490909 13.0909 1.09091 13.0909H2.24727C2.74909 17.64 6.36 21.2509 10.9091 21.7527V22.9091C10.9091 23.5091 11.4 24 12 24C12.6 24 13.0909 23.5091 13.0909 22.9091V21.7527C17.64 21.2509 21.2509 17.64 21.7527 13.0909H22.9091C23.5091 13.0909 24 12.6 24 12C24 11.4 23.5091 10.9091 22.9091 10.9091H21.7527ZM12 19.6364C7.77818 19.6364 4.36364 16.2218 4.36364 12C4.36364 7.77818 7.77818 4.36364 12 4.36364C16.2218 4.36364 19.6364 7.77818 19.6364 12C19.6364 16.2218 16.2218 19.6364 12 19.6364Z" fill="currentColor"/></g><defs><clipPath id="clip0_1812_17864"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>',
        'bg-white p-2 rounded-xl !text-2xl h-10 flex justify-center shadow-lg',
        'Center map',
        () => {
          map.setCenter(
            props.centerByMarker && props.properties.length > 0
              ? { lat: props.properties[0].latitude, lng: props.properties[0].longitude }
              : { lat: 25.2048, lng: 55.2708 }
          )
          map.fitBounds(
            new google.maps.LatLngBounds(
              new google.maps.LatLng(25.0948, 55.1608), // Slightly more southwest
              new google.maps.LatLng(25.3148, 55.3808) // Slightly more northeast
            )
          )
        }
      )
      centerControl.appendChild(button)
      return centerControl
    },
  ],
])

const addControl = (map: any, type: 'center' | 'zoom' | 'mapType', position: string) => {
  const controlDiv = document.createElement('div')
  controlDiv.classList.add('flex', 'flex-col', 'rounded-xl', 'mx-6')

  const button = controlsTypeMap.get(type)?.(map)

  controlDiv.appendChild(button)
  map.controls[position].push(controlDiv)
}

const aedFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'compact',
  maximumFractionDigits: 3,
})

const resetButtons = () => {
  const buttons = document.querySelectorAll('#status-buttons button')
  buttons.forEach((button) => {
    button.classList.remove('bg-primary-90')
    button.classList.remove('text-white')

    button.classList.add('bg-white')
    button.classList.add('text-black-90')
  })
}

const showInfoWindow = (property: PropertyTypes.Property, event: any) => {
  const status = statuses.value.find((status) => status.name === property.sales_status.en)
  if (!infoWindow.value || !props.showPropertyPopover) return
  const units = getPropertyUnits(property.apartments)
  // if there's many units with bedroom in title, remove from all except last one that has bedroom in title
  units.forEach((unit, index) => {
    if (unit.title?.includes('bedroom') && index !== units.length - 1) {
      unit.title = unit.title.replace('bedroom', '')
    }
  })

  const contentString = `<div id="info_window" class="flex flex-col gap-3 p-1 cursor-pointer">
      <div class="text-body-2 absolute left-4 top-4 flex flex-row items-center gap-2">
        <span class="${statusesButtonsColorsMap[status?.code || 'sold_out']} size-3 rounded-full"></span>
        ${property.sales_status?.en || '-'}
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-2">
          <h4>${property.title.en}</h4>
          <span class="text-body-2 text-black-60">${property.address}</span>
        </div>
        <div class="flex flex-row gap-3">
            <img src="${property.photo}" class="w-[187px] h-[116px] rounded-xl object-cover"/>
            <div class="flex flex-col gap-1 w-[260px]">
                <div class="flex flex-row justify-between items-center">
                    <span class="text-caption-1 text-black-40 w-1/3">Developer</span>
                    <span class="text-body-2 w-2/3 text-right">${property.developer.en}</span>
                </div>
                <div class="flex flex-row justify-between items-center">
                    <span class="text-caption-1 text-black-40 w-1/3">Unit Price</span>
                    <span class="text-body-2 w-2/3 text-right">${
                      property.price.min ? 'from ' + aedFormatter.format(Number(property.price.min)) : '-'
                    }</span>
                </div>
<div class="flex flex-row justify-between items-center">
                    <span class="text-caption-1 text-black-40 w-1/3">Price per m2</span>
                    <span class="text-body-2 w-2/3 text-right">${
                      property.price_m2?.min ? 'from ' + aedFormatter.format(property.price_m2.min) : '-'
                    }</span>
                </div>
                <div class="flex flex-row justify-between items-center">
                    <span class="text-caption-1 text-black-40 w-1/3">Type</span>
                    <span class="text-body-2 w-2/3 text-right">${units.map((u) => u.title).join(', ')}</span>
                </div>
                   <div class="flex flex-row justify-between items-center">
                    <span class="text-caption-1 text-black-40 w-1/3">Available Units</span>
                    <span class="text-body-2 w-2/3 text-right">${property.available_units_total_count}</span>
                </div>
            </div>
        </div>
      </div>
    </div>`

  infoWindow.value.setContent(contentString)

  infoWindow.value.setPosition(event.latLng)
  infoWindow.value.setOptions({ pixelOffset: new google.maps.Size(-240, 270) })
  infoWindow.value.open(myMapRef.value?.$mapObject)
  nextTick(() => {
    const infoWindowElement = document.getElementById(`info_window`)
    if (!infoWindowElement) return
    infoWindowElement?.addEventListener('click', () => {
      navigateTo('/properties/' + property.complex_id, {
        external: true,
        open: {
          target: '_blank',
        },
      })
    })
    const arrowElement = document.querySelector('.gm-style-iw-tc')

    if (arrowElement) {
      arrowElement.classList.add('hidden')
    }
  })
}

const { x, y } = usePointer()

const hideInfoWindow = debounce(
  (marker) => {
    const infoWindowElement = document.getElementById(`info_window`)
    if (!infoWindowElement) return

    // get info window position and check if cursor is still on the info window
    const infoWindowPosition = infoWindowElement.getBoundingClientRect()
    if (
      x.value > infoWindowPosition.left &&
      x.value < infoWindowPosition.right &&
      y.value > infoWindowPosition.top &&
      y.value < infoWindowPosition.bottom
    ) {
      return
    }
    // also check if cursor is around 2px of the marker
    if (
      x.value > marker.domEvent.x - 5 &&
      x.value < marker.domEvent.x + 5 &&
      y.value > marker.domEvent.y - 5 &&
      y.value < marker.domEvent.y + 5
    ) {
      return
    }

    infoWindow.value?.close()
  },
  200,
  {
    leading: false,
    trailing: true,
  }
)

defineExpose({ resetButtons })
</script>

<style scoped></style>
